<script setup>
    import { ref, onMounted, computed, watch, defineComponent } from 'vue';
    import { useElementBounding, useWindowSize } from '@vueuse/core';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import '@splidejs/vue-splide/css/core';

    defineComponent({
        components: {
            Splide,
            SplideSlide,
        },
    });

    const props = defineProps({
        eventos: Object
    })
    
    const itemRefs = ref([])
    const boundingNavs = ref([])
    const alves = ref([])

    var conta = 0;
    for (const key in props.eventos) {
        alves.value[conta] = parseInt(key);
        
        conta++;
    }

    const splide = ref();
    const timeline = ref()
    const { y } = useElementBounding(timeline)
    const { width, height } = useWindowSize()

    onMounted(() => {
        boundingNavs.value = itemRefs.value.map((ref) => {
            const { y, height } = useElementBounding(ref);
            return {
                id: parseInt(ref.id),
                y: y,
                height: height,
            };
        });

        splide.value.splide.on( 'click', function (e) {
            var ano = alves.value[e.index];
            var element = document.getElementById("linha-" + ano);
            element.scrollIntoView({ behavior: "smooth" });
        } );
        

        watch(proximo, (proximo) => {
            var index = _.findIndex(alves.value, function(o) { return o == proximo; });
            splide.value.splide.go(index);
        })

        watch(y, (y) => {
            if(y - height.value > -100) splide.value.splide.go(0);
        })
    })

    const ordem = ref([])

    const proximo = computed(() => {
        ordem.value = _.sortBy(boundingNavs.value, [function(o) { return Math.floor(Math.abs((360) - o.y - o.height / 2)); }]);
        var ano = 1938;
        if(ordem.value[0]) ano = ordem.value[0].id;
        return ano;
    })
</script>

<template>
    <div class="z-40 w-full sticky top-24 bg-black text-cinza overflow-x-auto">
        <Splide ref="splide" :options="{
            rewind: true,
            // width: '80px',
            autoWidth: true,
            focus: 'center',
            trimSpace: false,
            pagination: false,
            isNavigation: true,
            wheel: true,
            classes: {
                    arrows: 'splide__arrows hidden',
            }
        }" aria-label="My Favorite Images">
            <SplideSlide v-for="(events, ano, index) in eventos" :key="'slide-' + index"><div class="splide-ano">{{ ano }}</div></SplideSlide>
        </Splide>
    </div>
    
    <div ref="timeline" class="w-full flex justify-center text-white relative">
        <div class="absolute top-0 left-1/2 -translate-x-0.5 w-1 h-full bg-cinza rounded-full"></div>
        <div class="w-full md:w-4/5 lg:w-3/4 xl:w-2/3">
            <div ref="itemRefs" v-for="(events, ano, index) in eventos" :id="ano" class="relative">
                <div :id="'linha-' + ano" class="absolute -top-32 w-0 h-0"></div>
                <div class="w-full grid grid-cols-2 gap-16 relative mt-16">
                    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-6 h-6 bg-black border-4 border-cinza rounded-full flex-none"></div>
                    <div :class="index % 2 == 0 ? 'col-start-2' : 'text-right'">
                        <div class="destaque-big whitespace-nowrap text-transparent text-stroke-cinza" v-html="ano"></div>
                    </div>
                </div>
                <div v-for="event in events" class="w-full grid grid-cols-2 gap-16 relative my-12">
                    <div class="absolute top-[6px] left-1/2 -translate-x-1/2 w-3 h-3 bg-cinza rounded-full flex-none"></div>
                    <div :class="index % 2 == 0 ? 'col-start-2' : 'text-right'">
                        <div v-if="event.date" class="text-xs uppercase font-bold text-cinza" v-html="event.date"></div>
                        <div v-html="event.title"></div>
                        <div class="grid grid-cols-2 gap-6 mt-6">
                            <img v-for="(image, num) in event.media" :class="{'col-span-2': event.media.length == 1 || (event.media.length == 3 && num == 0)}" :src="'/storage/media/' + image.id + '/' + image.file_name" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .splide__slide {
        @apply h-24 flex items-center;
        .splide-ano {
            @apply px-5 whitespace-nowrap text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-bold text-transparent text-stroke-cinza text-center cursor-pointer;
        }

        &.is-active {
            .splide-ano {
                @apply text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-bold text-white text-stroke-none;
            }
        }
    }
</style>