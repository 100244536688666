import lodash from 'lodash';
window._ = lodash;

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Import modules...
import { createApp } from 'vue/dist/vue.esm-bundler.js';
import Front from './Front.vue'

createApp(Front).mount("#app")
