<template>
    <div class="joaodrag">
<div class="menu-wrapper">
  <div class="menu-item">
    <a href="#" class="active">Home</a>
    <a href="#">Contact</a>
    <a href="#">Courses</a>
    <a href="#">About</a>
    <a href="#">Service</a>
    <a href="#">Events</a>
    <a href="#">Courses</a>
    <a href="#">FAQ</a>
    <a href="#">Home</a>
    <a href="#">Contact</a>
    <a href="#">Courses</a>
    <a href="#">About</a>
    <a href="#">Service</a>
    <a href="#">Events</a>
    <a href="#">Courses</a>
    <a href="#">FAQ</a>
  </div> 
  <span class="pointer left-pointer dis">
    <i class="fa fa-chevron-left"></i>
  </span>
  <span class="pointer right-pointer">
    <i class="fa fa-chevron-right"></i>
  </span>
</div>
</div>
  </template>
  
  <script>
  export default {
    mounted(){
        // Get references to DOM elements
var lp = document.querySelector(".left-pointer");
var rp = document.querySelector(".right-pointer");
var mItems = document.querySelector(".menu-item");
var sc, pos;

// Add click event listeners to left and right pointers
lp.addEventListener("click", function () {
  sc = mItems.clientWidth - 60;
  pos = mItems.scrollLeft - sc;
  animateScroll(mItems, pos);
});

rp.addEventListener("click", function () {
  sc = mItems.clientWidth - 60;
  pos = mItems.scrollLeft + sc;
  animateScroll(mItems, pos);
});

var scrollLeftPrev = 0;

// Add scroll event listener to menu items
mItems.addEventListener("scroll", function () {
  var newScrollLeft = mItems.scrollLeft,
    width = mItems.clientWidth,
    scrollWidth = mItems.scrollWidth;
  var offset = 8;

  if (scrollWidth - newScrollLeft - width < offset) {
    console.log("right end");
    rp.classList.add("dis");
  } else {
    rp.classList.remove("dis");
  }

  if (newScrollLeft === 0) {
    lp.classList.add("dis");
  } else {
    lp.classList.remove("dis");
  }

  scrollLeftPrev = newScrollLeft;
});

// Add mouse event listeners for slider drag functionality
var slider1 = document.querySelector(".menu-item");
var isDown = false;
var startX;
var scrollLeft;

slider1.addEventListener("mousedown", function (e) {
  isDown = true;
  slider1.classList.add("active");
  startX = e.pageX - slider1.offsetLeft;
  scrollLeft = slider1.scrollLeft;
});

slider1.addEventListener("mouseleave", function () {
  isDown = false;
  slider1.classList.remove("active");
});

slider1.addEventListener("mouseup", function () {
  isDown = false;
  slider1.classList.remove("active");
});

slider1.addEventListener("mousemove", function (e) {
  if (!isDown) return;
  e.preventDefault();
  var x = e.pageX - slider1.offsetLeft;
  var walk = (x - startX) * 3; //scroll-fast
  slider1.scrollLeft = scrollLeft - walk;
});

// Helper function to animate scroll
function animateScroll(element, position) {
  var startTime = null;
  var duration = 500; // Adjust as needed

  function animation(currentTime) {
    if (startTime === null) {
      startTime = currentTime;
    }

    var timeElapsed = currentTime - startTime;
    var scrollProgress = timeElapsed / duration;

    element.scrollLeft = easeInOutQuad(scrollProgress, scrollLeft, position);

    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    }
  }

  requestAnimationFrame(animation);
}

// Helper function for easing in and out
function easeInOutQuad(t, b, c) {
  t /= 0.5;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

    }
  };
  </script>
  