<template>
    <div class="flex items-center">
        <div v-if="qual" class="text-xs uppercase" v-html="qual.title"></div>
        <a :href="'/' + pages[prevIndex].slug" @mouseenter="hover(prevIndex)" @mouseleave="qual = false" class="cursor-pointer w-6 h-6 rounded-full border border-cinza hover:border-white text-cinza hover:text-white flex items-center justify-center ml-2"><svg class="w-3 h-3"><use xlink:href="/storage/img/hero.svg#chevron-left" style="--stroke-width: 3px;" /></svg></a>
        <a :href="'/' + pages[nextIndex].slug" @mouseenter="hover(nextIndex)" @mouseleave="qual = false" class="cursor-pointer w-6 h-6 rounded-full border border-cinza hover:border-white text-cinza hover:text-white flex items-center justify-center ml-2"><svg class="w-3 h-3"><use xlink:href="/storage/img/hero.svg#chevron-right" style="--stroke-width: 3px;" /></svg></a>
    </div>
</template>

<script>
    export default {
        props: ['pages', 'pagina'],
        data(){
          return {
            atual: false,
            prevIndex: false,
            nextIndex: false,
            qual: false
          }  
        },
        created(){
            var este = this;
            este.atual = _.findIndex(este.pages, function(page) { return page.id == este.pagina.id; });

            este.prevIndex = este.atual - 1;
            este.nextIndex = este.atual + 1;
            
            if(este.atual == 0){
                este.prevIndex = este.pages.length - 1; 
            }

            if(este.atual == este.pages.length - 1){
                este.nextIndex = 0; 
            }
        },
        methods: {
            hover(index){
                var este = this;
                este.qual = este.pages[index];
            }
        }
    }
</script>