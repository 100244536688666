<template>
    <Carousel :autoplay="autoplayDelay" :transition="500" :pauseAutoplayOnHover="false" :wrapAround="true" @init="handleInit" @slide-end="handleSlideEnd" @slide-start="handleSlideStart" @mouseenter="pauseTimer" @mouseleave="resumeTimer">
      <Slide v-for="(noticia, index) in noticias" :key="noticia.id">
        <div class="carousel__item" :style="'background-image:url(/storage/img/noticias/' + noticia.slug + '.jpg)'">
          <div class="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-b from-transparent to-black opacity-70"></div>
          <div class="relative w-full h-full flex flex-col justify-between">
            <div class="w-full h-2 mt-1">
          <!-- <div v-if="slide == index" class="h-1 bg-black transition-all duration-100" :style="'width:' + percentage + '%'"></div> -->
        </div>
          <div class="w-3/4 px-16 py-12">
            <div class="destaque uppercase [text-shadow:_1px_1px_4px_rgb(0_0_0_/_50%)]" v-html="noticia.title"></div>
            <div class="text-sm mt-3 [text-shadow:_1px_1px_4px_rgb(0_0_0_/_50%)]" v-html="noticia.intro"></div>
            <div class="flex mt-8">
              <a :href="'/noticias/' + noticia.slug" class="btn branco">saber mais</a>
            </div>
          </div>
        </div> 
        </div>
      </Slide>
  
      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </template>

  <script>
  import { defineComponent } from 'vue'
  import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
  
  export default defineComponent({
    name: 'Noticias',
    props: ['noticias'],
    data(){
      return {
        autoplayDelay: 4000,
        countdown: 4000,
        timer: null,
        slide: 0
      }
    },
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
    },
    // computed:{
    //   percentage(){
    //     return ((this.autoplayDelay - this.countdown) / this.autoplayDelay) * 100;
    //   }
    // },
    // methods: {
    //   handleSlideEnd(data) {
    //     this.slide = data.currentSlideIndex;
    //     this.countdown = this.autoplayDelay;
    //   },
    //   handleSlideStart(data) {
    //     this.startCountdown(data.slidingToIndex);
    //   },
    //   pauseTimer(){

    //   },
    //   resumeTimer(){

    //   },
    //   handleInit(){
    //     this.startCountdown(0);
    //   },
    //   startCountdown() {
    //     var este = this;
        
    //     este.timer = setInterval(() => {
    //       if (este.countdown > 0) {
    //         este.countdown -= 100; // Decrement by 1 second (1000ms)
    //       } else {
    //         clearInterval(este.timer); // Stop the timer when countdown reaches 0
    //       }
    //     }, 100);
            
    //   }
    // },
    // beforeUnmount() {
    //   clearInterval(this.timer);
    // },
  })
  </script>