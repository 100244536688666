<template>
    <div id="homeSlider" class="relative w-full h-total bg-center bg-cover" :style="'background-image: url(/storage/img/modalidades/' + modalidades[modalidadeAtual].slug + '.jpg)'">
        <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-black via-white to-black mix-blend-multiply"></div>
        <div class="absolute bottom-0 left-0 w-full h-[20%] bg-gradient-to-b from-transparent to-black"></div>
        <div class="absolute top-0 left-0 w-full h-full flex">
            <div class="relative lateral h-total">
                <div class="painel absolute top-0 left-0 w-0 h-full bg-black transition-all duration-500"></div>
            </div>
            <div class="grow grid grid-cols-8">
                <div v-for="i in 8" class="relative w-full h-total">
                    <div class="painel absolute top-0 left-0 w-0 h-full bg-black transition-all duration-500"></div>
                </div>
            </div>
            <div class="relative lateral h-total">
                <div class="painel absolute top-0 left-0 w-0 h-full bg-black transition-all duration-500"></div>
            </div>
        </div>

        <div class="absolute top-0 left-0 conteudo mt-80 text-white">
            <div class="grid grid-cols-8 gap-y-6">
                <div class="col-start-2 col-span-7 font-bold uppercase">#allblacks</div>
                <div class="h-[96px] flex items-center">
                    <div @click="clickaModalidade(prevIndex)" @mouseenter="hover = prevIndex" @mouseleave="hover = false" class="cursor-pointer w-10 h-10 rounded-full border-2 border-white hover:bg-white hover:text-black bg-opacity-20 flex items-center justify-center"><svg class="w-4 h-4"><use xlink:href="/storage/img/hero.svg#chevron-left" style="--stroke-width: 3px;" /></svg></div>
                    <div @click="clickaModalidade(nextIndex)" @mouseenter="hover = nextIndex" @mouseleave="hover = false" class="cursor-pointer w-16 h-16 rounded-full border-2 border-white hover:bg-white hover:text-black bg-opacity-20 flex items-center justify-center ml-2"><svg class="w-4 h-4"><use xlink:href="/storage/img/hero.svg#chevron-right" style="--stroke-width: 3px;" /></svg></div>
                </div>
                <div class="col-span-5 text-8xl font-bold uppercase overflow-hidden">
                    <div id="titleModalidade" class="text-8xl font-bold uppercase" v-html="modalidades[modalidadeAtual].title"></div>
                </div>
                <div class="col-span-2 flex justify-end">
                    <div class="h-[82px] border-b-4 border-white text-7xl font-bold" v-html="modalidadeAtual + 1"></div>
                    <div class="ml-5 mt-[6px] text-2xl font-bold">/ <span v-html="totalModalidades"></span></div>
                </div>
            </div>
            <div class="grid grid-cols-8 mt-6">
                <div class="col-start-2 col-span-5" :class="estaMudar ? 'opacity-0' : 'opacity-100 anima'">
                    <div class="font-bold [text-shadow:_1px_1px_4px_rgb(0_0_0_/_30%)]" v-html="modalidades[modalidadeAtual].intro"></div>
                    <div class="flex mt-6">
                        <a :href="modalidades[modalidadeAtual].url" class="btn big branco">Saber Mais</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="absolute bottom-12 left-0 conteudo text-white">
            <div class="grid grid-cols-8 gap-y-6">
                <div class="col-start-5 col-span-4">
                    <div class="w-full grid grid-cols-6">
                        <div v-for="(modalidade, index) in modalidades">
                            <div @click="clickaModalidade(index)" class="thumb-wrapper hover:ativo" :class="{ativo: index == modalidadeAtual}">
                                <div class="thumb-inner">
                                    <div class="thumb-img" :style="'background-image: url(/storage/img/modalidades/' + modalidade.slug + '.jpg)'"></div>
                                    <div class="thumb-titulo" v-html="modalidade.title"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="absolute bottom-[5dvh] left-0 conteudo h-[15dvh] bg-black flex">
            <div class="w-full h-full grid grid-cols-8 gap-[1px]">
                <div v-for="(modalidade, index) in modalidades" class="relative bg-center bg-cover grayscale opacity-50" :class="{hidden: index == modalidadeAtual}" :style="'background-image: url(/storage/img/modalidades/' + modalidade.slug + '.jpg)'">
                    <div @click="clickaModalidade(index)" @mouseenter="hover = index" @mouseleave="hover = false" class="w-full h-[10dvh] hover:h-[15dvh] hover:rounded-t-xl transition-all duration-500"></div>
                </div>
            </div>
        </div> -->
    </div>
    
    
    
    
    <!-- <div class="w-full h-total relative grid grid-cols-18 bg-center bg-cover" :style="'background-image: url(/storage/img/modalidades/' + modalidades[modalidadeAtual].slug + '.jpg)'">
        <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-black via-white to-black mix-blend-multiply"></div>
        <div class="absolute bottom-0 left-0 w-full h-[calc(10dvh+2px)] bg-white"></div>
        <div class="absolute bottom-0 left-0 w-full h-[15dvh] grid grid-cols-18">
            <div class="relative"><div class="absolute bottom-0 left-0 w-full bg-black h-[10dvh]"></div></div>
            <div v-for="(modalidade, index) in modalidades" class="col-span-2 relative" :class="{hidden: index == modalidadeAtual}">
                <div class="modalidade-thumb" :class="{hidden: index == modalidadeAtual, 'modalidade-hover': hover === index}" :style="'background-image: url(/storage/img/modalidades/' + modalidade.slug + '.jpg)'"></div>
            </div>
            <div class="relative"><div class="absolute bottom-0 left-0 w-full bg-black h-[10dvh]"></div></div>
        </div>
        <div v-for="i in 10" class="relative w-full h-total bg-fixed" :class="{'col-span-2': i != 1 && i != 10}">
            <div class="painel absolute top-0 left-0 w-0 h-full bg-black transition-all duration-500"></div>
        </div>
    </div>
    <div class="absolute top-0 left-0 w-full h-total pt-80 text-white">
        <div>
            <div class="grid grid-cols-18">
                <div class="col-span-3"></div>
                <div class="font-bold uppercase col-span-2">#allblacks</div>
            </div>
            <div class="grid grid-cols-18 my-6">
                <div></div>
                <div class="col-span-2">
                    <div class="w-full h-[96px] flex items-center">
                        <div @click="clickaModalidade(prevIndex)" @mouseenter="hover = prevIndex" @mouseleave="hover = false" class="w-10 h-10 rounded-full border-2 border-white flex items-center justify-center"><svg class="w-4 h-4"><use xlink:href="/storage/img/hero.svg#chevron-left" style="--stroke-width: 3px;" /></svg></div>
                        <div @click="clickaModalidade(nextIndex)" @mouseenter="hover = nextIndex" @mouseleave="hover = false" class="w-16 h-16 rounded-full border-2 border-white flex items-center justify-center ml-2"><svg class="w-4 h-4"><use xlink:href="/storage/img/hero.svg#chevron-right" style="--stroke-width: 3px;" /></svg></div>
                    </div>
                </div>
                <div id="titleModalidade" class="col-span-10 text-8xl font-bold uppercase overflow-hidden" v-html="modalidades[modalidadeAtual].title"></div>
                <div class="col-span-4 w-full flex justify-end">
                    <div class="h-[82px] border-b-4 border-white text-7xl font-bold" v-html="modalidadeAtual + 1"></div>
                    <div class="ml-5 mt-[6px] text-2xl font-bold">/ <span v-html="totalModalidades"></span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="absolute bottom-0 left-0 w-full h-[15dvh] grid grid-cols-18">
        <div class="relative"><div class="absolute bottom-0 left-0 w-full bg-black h-[10dvh]"></div></div>
        <div v-for="(modalidade, index) in modalidades" class="col-span-2 relative" :class="{hidden: index == modalidadeAtual}">
            <div @click="clickaModalidade(index)" @mouseenter="hover = index" @mouseleave="hover = false" class="absolute bottom-0 left-0 w-full h-[10dvh] hover:h-[15dvh] hover:rounded-t-xl transition-all duration-500"></div>
        </div>
        <div class="relative"><div class="absolute bottom-0 left-0 w-full bg-black h-[10dvh]"></div></div>
    </div> -->
</template>

<script>
    import ShuffleText from 'shuffle-text';
    
    export default {
        props: ['modalidades'],
        data(){
          return {
            modalidadeAtual: 0,
            totalModalidades: this.modalidades.length,
            hover: false,
            estaMudar: false
          }  
        },
        computed: {
            nextIndex(){
                var este = this;
                var proximo = este.modalidadeAtual + 1;
                if(proximo == este.totalModalidades) return 0;
                return proximo;
            },
            prevIndex(){
                var este = this;
                if(este.modalidadeAtual == 0) return (este.totalModalidades - 1);
                var anterior = este.modalidadeAtual - 1;
                return anterior;
            },
            filteredModalidades() {
                // Filter out the object at modalidadeAtual index
                return this.modalidades.filter((_, index) => index !== this.modalidadeAtual);
            },
        },
        methods: {
            clickaModalidade(index) {
                var este = this;
                este.hover = false;
                este.estaMudar = true;
                
                const elements = document.querySelectorAll('.painel');

                elements.forEach(element => {
                    element.style.width = '100%'; // Change to your desired color
                });

                const el = document.getElementById('titleModalidade');

                el.innerText = este.modalidades[index].title;
                var text = new ShuffleText(el);
                text.start();
                
                setTimeout(function() {
                    este.modalidadeAtual = index;

                    elements.forEach(element => {
                        element.style.width = 0; // Change to your desired color
                    });

                    este.estaMudar = false;
                }, 500);
            }
        }
    }
</script>