<script>
    import { useWindowScroll, useWindowSize } from '@vueuse/core';
    import { VueMarqueeSlider } from 'vue3-marquee-slider';
    import '../../node_modules/vue3-marquee-slider/dist/style.css';

    import PageNav from './Mine/PageNav.vue';
    import HomeSlider from './Mine/HomeSlider.vue';
    import Agenda from './Mine/Agenda.vue';
    import Noticias from './Mine/Noticias.vue';
    import Timeline from './Mine/Timeline.vue';
    
    export default {
        components: {
            VueMarqueeSlider,
            PageNav,
            HomeSlider,
            Agenda,
            Noticias,
            Timeline
        },
        data(){
          return {
            scroll: useWindowScroll(),
            windowSize: useWindowSize(),
            menu: false,
            parceirosPaused: true
          }  
        },
        watch: {
            menu(oldValue, newValue){
                if(newValue){
                    document.body.classList.remove("no-scroll");
                } else {
                    document.body.classList.add("no-scroll");
                }
            }
        },
        mounted(){
            var este = this;
            setTimeout(function (){
                este.parceirosPaused = false;
            }, 100)
        },
        methods: {
            toggleMenu(){
                var este = this;
                este.menu = !este.menu;
            },
            openMenu(){
                var este = this;
                este.menu = true;
            },
            openNewTab(url) {
                window.open(url, '_blank');
            },
            highlightImage(index) {
                // Set the opacity of all elements with the class "parceiro-item" to 50%
                const imgItems = document.querySelectorAll('#marquee-parceiros img');
                imgItems.forEach((item) => {
                    item.style.opacity = '0.5';
                });

                // Set the opacity of the hovered image to 100%
                this.activeImageIndex = index;
                const hoveredImage = imgItems[index];
                hoveredImage.style.opacity = '1';
            },
            resetOpacity() {
                // Reset the opacity of all elements with the class "parceiro-item" to 100%
                const imgItems = document.querySelectorAll('#marquee-parceiros img');
                imgItems.forEach((item) => {
                    item.style.opacity = '1';
                });

                // Reset the active image index
                this.activeImageIndex = null;
            },
        }
    }
</script>

<style>
#draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
</style>